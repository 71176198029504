import * as actions from './actions';
import { FILTERS_TYPE, ROLES_LIST, SERVICES_LIST } from '../constants';
import { AVAILABLE_FILTERS } from './actions';
import { cloneDeep } from 'lodash';

export const initialState = {
  [actions.FILTERS]: cloneDeep(FILTERS_TYPE),
  [actions.SELECTED_FILTERS]: cloneDeep(FILTERS_TYPE),
  [actions.AVAILABLE_FILTERS]: cloneDeep(FILTERS_TYPE),
  [actions.CLEAR_ALL_FILTERS]: 0,
  [actions.LOADING]: false,
  [actions.ROLES_FILTERS]: cloneDeep(ROLES_LIST),
  [actions.SERVICES_FILTERS]: cloneDeep(SERVICES_LIST),
};
