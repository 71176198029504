import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledUsageMonitoringDrillThrough = styled(Grid)(({ theme }) => {
  return {
    '.select-roles-dropdown, .select-services-dropdown': {
      '.qtm-text-input > input': {
        width: '240px',
      },
    },
  };
});
